@import '../../main.module.scss';


.OurPartnersContainer {
    display: flex;
    flex-direction: column;
    @include min-width(tablet) {
        flex-direction: row;
    }

    .NewHomepage {
      flex-direction: column-reverse;
      max-height: 620px;

      @include min-width(tablet) {
        flex-direction: row-reverse;
      }
    }
}

.OurPartnerImage {
    @include min-width(tablet) {
        flex: 1;
    }
}

.OurPartnersContentContainer {
    background-color: $core3;
    order: 2;

    @include min-width(tablet) {
        flex: 1;
        order: unset;
    }
}

.OurPartnersContent {
    display: flex;
    flex-direction: column;
    padding: 49px 20px 0;
    text-align: center;
    position: relative;
    max-height: 100%;

    @include min-width(tablet) {
        text-align: left;
        padding: 45px 0 0 55px;
    }
    @include min-width(desktop) {
        padding: 107px 0 0 100px;
    }
}

.OurPartnerBody {
    padding: 0;
    margin: 0;
    @include min-width(tablet) {
        padding-right: 37px;
        max-width: 500px;
        z-index: 2;
        position: relative;
    }
}

.Overline {
  font-size: 14px;
  margin: 0;
  padding-bottom: 11px;
  @include primaryFont(500);
  letter-spacing: 1px;

  @include min-width(desktop) {
    padding-bottom: 24px;
  }
}


.PartnerList {
    padding: 0;
    margin: 0;
    max-width: 100%;

    @include min-width(tablet) {
        position: relative;
        z-index: 0;
    }
    @include min-width(medium-desktop) {
        max-width: 500px;
    }
}

.NewHomePage {
  @include min-width(medium-tablet) {
    max-height: 620px;
    flex-direction: row-reverse;
  }

  picture {
    order: 10;
    width: 100%;
    overflow: hidden;

    @include min-width(medium-tablet) {
      order: 0;
      max-width: 50%;
    }

    img {
      width: 100%;
      object-fit: cover;
      max-height: 358px;

      @include min-width(medium-tablet) {
        max-height: inherit;
      }
    }
  }

  .OurPartnerBody {
    margin-bottom: 58px;

    @include min-width(medium-tablet) {
      margin-bottom: 35px;
    }
  }

  .PartnerList {
    margin-bottom: 40px;

    @include min-width(medium-tablet) {
      max-width: 80%;
    }

    @include min-width(large-desktop) {
      max-width: 500px;
    }
  }

  .OurPartnersContentContainer {
    @include min-width(desktop) {
      position: relative;
    }
  }

  .OurPartnersContent {
    @include min-width(desktop) {
      padding-left: 35px;
    }

    @include min-width(large-desktop) {
      position: absolute;
      width: 1440px;
      left: 100%;
      transform: translateX(-50%);
      padding-left: 30px;
    }
  }
}
